/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/merriweather-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
       url('/assets/fonts/merriweather-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/merriweather-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/merriweather-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/merriweather-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/merriweather-v21-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/merriweather-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Italic'), local('Merriweather-Italic'),
       url('/assets/fonts/merriweather-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/merriweather-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/merriweather-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/merriweather-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/merriweather-v21-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* merriweather-sans-regular - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/merriweather-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/merriweather-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/merriweather-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/merriweather-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/merriweather-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/merriweather-sans-v13-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* merriweather-sans-italic - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/merriweather-sans-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/merriweather-sans-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/merriweather-sans-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/merriweather-sans-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/merriweather-sans-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/merriweather-sans-v13-latin-italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

:root {
  --body-bg: #ffffff;
  --main-bg: #e9e9e9; 
  --menu-bg: #2b2623;
  --foot-bg: #2b2623; 
  --btn-col: #dba047; 
  --btn-hov: #ae4e26;
}

* {
  font-family: Merriweather, Merriweather Sans; 
}

q {
  quotes: "“" "”" "‘" "’";
  font-family: Merriweather;
}

q::before {
    content: open-quote;
}

q::after {
    content: close-quote;
}

figcaption {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

figure.main {
  width: 100%;
  margin: 0;
  overflow: hidden;
}

figure.main img {
  width: 100%;
  border: 3px var(--btn-hov) solid;
  border-radius: 10px; 
  object-fit: contain;
}

figure.small-image {
  float: right;  
  margin-left: 15px; 
  width: 35vw; 
  margin-right: 0;
}

figure.small-image img {
  width: 100%;
  height: auto; 
  border-radius: 8px; 
}

figure.small-image figcaption {
  text-align: center;
  font-size: 0.8em;
  margin-top: 5px;
}

body {
  /* border: 2px solid rgba(167, 47, 20, 1); */
  /* border-radius: 25px; */
  margin-left: 10%;
  margin-right: 10%;
  padding: 10px;
  background-color: var(--body-bg);
}

.main h1, h2, h3 {
  font-variant: small-caps;
  padding: .3em .3em;
}

.main h1 {
  border-top: 5px double rgba(167, 47, 20, 1);
  border-bottom: 5px double rgba(167, 47, 20, 1);
  text-align: center;
}

.main h2 {
  border-bottom: 5px solid rgba(167, 47, 20, 1);
}

.main p {
  font-size: 16px;
  text-align: justify;
}

.main img {
  max-width: 100%;
}

div.pfp {
  padding: .5em;
}

div.main {
  background-color: var(--main-bg);
  padding: 2em;
  border-radius: 10px;
  min-height: 500px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.pfp img {
  border: 5px double rgba(167, 47, 20, 1);
  border-radius: 10px;
  max-width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pfp p {
  text-align: center;
  font-style: italic;
  font-size: 12px; 
}

div.menu {
  /* background: #ae4e26; */
  background: var(--menu-bg); 
  padding: .5em;
  margin-bottom: 2em;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  min-height: 3em;
  height: auto;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.menu p {
  color: white;
  text-decoration: none;
  padding: .5em .5em; 
  margin-left: .5em;
  text-align: right;
  border-radius: 10px;
}

.menu a {
  color: white;
  text-decoration: none;
  padding: .8em .8em;
  margin-right: 1em;
  margin-left: 1em;
  min-width: 100px;
  text-align: center;
  background-color: var(--btn-col);
  display:inline-block;
  font-size: medium;
  border-radius: 10px;
  margin-bottom: .5em;
  margin-top: .5em;
}

.menu a:hover, .contact a:hover, a.webring-btn:hover {
  background-color: var(--btn-hov);
}

.main a, footer a {
  color: #ae4e26;
}

.main a:hover, footer a:hover {
  text-decoration: none;
}

div.contact {
  text-align: center;
}

.contact a {
  color: white;
  text-decoration: none;
  padding: .8em .8em;
  text-align: center;
  background-color: var(--btn-col);
  display:grid;
  font-size: medium;
  border-radius: 10px;
  margin-bottom: 1em;
}

dt {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}

dd::before {
  content: "↪ ";
}

dd {
  margin: 0;
  padding: 0 0 0.5em 0;
}

/*.contact a:last-child {
  min-width: 94%;
} */

a.webring-btn {
  color: white;
  text-decoration: none;
  padding: .5em .5em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  min-width: 75px;
  text-align: center;
  background-color: var(--btn-col);
  display: inline-block;
  font-size: medium;
  border-radius: 10px;
}

div.webring-btn-container {
  
}

div.webring {
  border-top: 2px solid var(--btn-hov);
  border-bottom: 2px solid var(--btn-hov);
  text-align: right;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  margin-top: 5px;
}

.webring span {
  float: left;
  padding: auto 0;
}

footer {
  /* background: #ae4e26; */
  background: var(--foot-bg); 
  padding: 1em;
  margin-top: 2em;
  overflow: hidden;
  text-align: center;
  align-items: center;
  height: auto;
  border-radius: 10px;
  min-height: 3em;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

footer p {
  color: white;
}

footer img {
  flex-shrink: 0;
  flex-basis: auto;
  height: auto;
  image-rendering: pixelated;
}

footer a {
  width: 100%;
}

div.web-button-container {
  text-align: center;
}

div.web-button-container a {
  text-decoration: none;
} 

img.web-button {
  width: 88px;
  height: 31px;
  image-rendering: pixelated;
}

img.emoticon:hover, img.web-button:hover {
  transform: scale(3);
  transition-duration: 300ms;
}

img.emoticon:hover {
  transform: scale(4) !important;
}

img.emoticon {
  vertical-align: middle;
  margin-bottom: .2em;
}

img {
  transition-duration: 300ms;
}

div.quick-about {
  text-align: center;
}

/* Responsive design stuff */
@media only screen and (max-width: 900px) {
  .menu a {
    font-size: medium;
    min-width: 90px;
  }

  div.menu {
    padding: .5em;
    height: auto;
  }

  body {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 700px) {
  .menu a {
    font-size: smaller;
    min-width: 80px;
    margin-left: 0.1em;
    margin-right: 0.1em;
  }

  footer {
    flex-direction: column;
  }
}

/* CSS gallery from w3schools.com */

div.art {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

div.gallery {
  border: 5px double rgba(167, 47, 20, 0.3);
  margin-bottom: 10px;
}

div.gallery:hover {
  border: 5px double #dba047;
}

div.gallery img {
  width: 100%;
  height: 200px;
  object-fit: scale-down;
}

div.desc {
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 900px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
  
  div.art {
    display: inherit;
    justify-content: inherit
  }
}

@media only screen and (max-width: 700px) {
  figure.small-image {
    width: 100%; 
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }

  div.art {
    display: inherit;
    justify-content: inherit
  }

  a.webring-btn {
    min-width: 40px;
    margin-right: 0.2em;
    margin-left: 0.2em;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

code {
  font-family: monospace;
  background-color: lightgrey;
  border: 1px grey;
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-bg: rgb(49, 49, 49);
    --main-bg: #191919; 
    --menu-bg: #191919;
    --foot-bg: #191919; 
    --btn-hov: #dba047; 
    --btn-col: #ae4e26;
  }

  code {
    background-color: darkgray;
  }

  div.gallery {
    border: 5px double rgba(167, 47, 20, 1);
  }

  .main a {
    color: #dba047;
  }

  .contact a, li, span, a.webring-btn, div.desc, p, h1, h2, h3, b, figcaption, dd  {
    color: white; 
  }
}